import React from "react";
import {
  faCaretDown,
  faCaretUp,
  faHighlighter,
  faPen,
  faPlus,
  faSort,
  faSync,
  faTrash,
  faEyeSlash,
  faAlignJustify,
  faAlignLeft,
  faSearch,
  faFilePdf,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import {
  ButtonIcon,
  Card,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  Td,
  Loader,
} from "ui-kit-ck-consultant";

import ModalRecallFacts from "../../../components/folder/recall-facts/ModalRecallFacts";

import { getFileShorcut } from "../../../utils/general";

import AuthContext from "../../../context/AuthContext";

import ModalRecallFactsSearch from "../../../components/folder/recall-facts/ModalRecallFactsSearch";

import { recallFactsPDF } from "../../../requests/folder";

import * as XLSX from "xlsx";

export default class RecallFacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      isModalRecallFacts: false,
      isModalRecallFactsSearch: false,
      idxModalRecallFacts: null,
    };
  }

  static contextType = AuthContext;

  setData = (tmpData, callback = () => {}) => {
    if (this.context.updateDataExpertise) {
      this.context.updateDataExpertise("recallFacts", tmpData, callback);
    } else if (this.context.updateDataReport) {
      this.context.updateDataReport("recallFacts", tmpData, callback);
    }
  };

  setDataGeneral = (tmpData, callback = () => {}) => {
    this.context.updateDataFolder("dataRecallFacts", tmpData, callback);
  };

  sort = async () => {
    return await new Promise((resolve) => {
      let tmpDataRecallFacts = this.props.dataRecallFacts.filter(
        (element) => element
      );

      tmpDataRecallFacts.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
      });

      this.setDataGeneral(tmpDataRecallFacts, () => resolve());
    });
  };

  getData = () => {
    let parties = this.context.parties;
    let maxMileage = 0;

    let filter = this.props.dataRecallFacts.filter((element) => element);

    if (this.props.date) {
      filter = filter.filter((element) =>
        moment(element.date).isSameOrBefore(moment(this.props.date))
      );
    }

    filter.forEach((line) => {
      line.isRed = undefined;
      if (/^\d+$/.test(line.mileage)) {
        if (maxMileage < parseInt(line.mileage)) {
          maxMileage = parseInt(line.mileage);
        }

        if (maxMileage > parseInt(line.mileage)) {
          line.isRed = true;
        }
      }
    });

    const findPositionToken = (token) => {
      let position = 0;

      filter.forEach((element, idx) => {
        if (element.token === token) {
          position = idx;
        }
      });

      return position;
    };

    let title = "km / hr";
    if (this.props.dataExpertiseConditions) {
      if (this.props.dataExpertiseConditions.switchMileageHour) {
        title = "hr";
      } else {
        title = "km";
      }
    }

    const getRecallFactXLSX = (data, workBook) => {
      this.setState({ isLoad: true }, () => {
        const workSheet = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(workBook, workSheet, "");
        XLSX.writeFile(
          workBook,
          `Rappel_des_faits_${moment().format("YYYYMMDDHHmmss")}.xlsx`
        );

        this.setState({ isLoad: false });
      });
    };

    const getRecallFactsPDF = (filter) => {
      this.setState({ isLoad: true }, () => {
        const newArray = filter.map((element) => ({
          ...element,
          speaker: parties[element.speaker]
            ? `${
                parties[element.speaker].society
                  ? `${parties[element.speaker].society} - `
                  : ""
              }${parties[element.speaker].kind} ${
                parties[element.speaker].name
              }`
            : element.speaker === "Autre"
            ? element.speakerName
            : "",
        }));

        recallFactsPDF({ data: newArray }, (result) => {
          if (result.success) {
            let a = document.createElement("a");
            a.href = "data:application/pdf;base64," + result.data;
            a.download = "Rappel_des_faits.pdf";
            a.click();

            this.setState({ isLoad: false });
          }
        });
      });
    };

    return (
      <>
        <Table>
          <Thead>
            <Tr>
              <Th></Th>
              <Th>Date</Th>
              <Th>{title}</Th>
              <Th>Fait</Th>
              <Th>Intervenant</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filter.map((element, idx) => {
              if (!this.props.data || !this.props.data[element.token]) {
                return (
                  <Tr key={idx}>
                    <Td>
                      <div
                        className="d-flex"
                        style={{
                          flexDirection: "column",
                        }}
                      >
                        {idx !== 0 ? (
                          <ButtonIcon
                            small
                            className="blue m-auto mb-0 p-0"
                            onClick={() => {
                              let tmpDataRecallFacts =
                                this.props.dataRecallFacts;

                              const oldLine = filter[idx - 1];
                              const oldLinePosition = findPositionToken(
                                oldLine.token
                              );
                              const newLinePosition = findPositionToken(
                                element.token
                              );

                              tmpDataRecallFacts[oldLinePosition] = filter[idx];
                              tmpDataRecallFacts[newLinePosition] = oldLine;

                              this.setDataGeneral(tmpDataRecallFacts);
                            }}
                          >
                            <FontAwesomeIcon icon={faCaretUp} />
                          </ButtonIcon>
                        ) : null}
                        {idx !== this.props.dataRecallFacts.length - 1 ? (
                          <ButtonIcon
                            small
                            className="blue m-auto mt-0 p-0"
                            onClick={() => {
                              let tmpDataRecallFacts =
                                this.props.dataRecallFacts;
                              const oldLine = filter[idx + 1];
                              const oldLinePosition = findPositionToken(
                                oldLine.token
                              );
                              const newLinePosition = findPositionToken(
                                element.token
                              );

                              tmpDataRecallFacts[oldLinePosition] = filter[idx];
                              tmpDataRecallFacts[newLinePosition] = oldLine;

                              this.setDataGeneral(tmpDataRecallFacts);
                            }}
                          >
                            <FontAwesomeIcon icon={faCaretDown} />
                          </ButtonIcon>
                        ) : null}
                      </div>
                    </Td>
                    <Td>
                      {element.date
                        ? moment(element.date).format("DD/MM/YYYY")
                        : ""}{" "}
                      {element.isDateEnd && element.dateEnd
                        ? `- ${moment(element.dateEnd).format("DD/MM/YYYY")}`
                        : ""}
                    </Td>
                    <Td className={element.isRed ? "red bg-red-light" : ""}>
                      {element.mileage}
                    </Td>
                    <Td>
                      <div
                        style={{
                          ...(element.isHighlighter
                            ? {
                                fontWeight: "bold",
                              }
                            : {}),
                          ...(this.context.getJustifyCard &&
                          this.context.getJustifyCard("RecallFacts")
                            ? { textAlign: "justify" }
                            : {}),
                        }}
                        dangerouslySetInnerHTML={{
                          __html: `${
                            element.description
                              ? element.description.replace(/\n/g, "<br/>")
                              : ""
                          }
                    ${
                      element.file
                        ? `<br/><br/><img
                          alt="imageRecallFacts"
                          className="m-auto"
                          src="${getFileShorcut(this.context, element.file)}"
                          style="height: 200px; max-width: 250px }"
                        />`
                        : ""
                    }`,
                        }}
                      ></div>
                    </Td>
                    <Td>
                      {parties[element.speaker]
                        ? `${
                            parties[element.speaker].society
                              ? `${parties[element.speaker].society} - `
                              : ""
                          }${parties[element.speaker].kind} ${
                            parties[element.speaker].name
                          }`
                        : element.speaker === "Autre"
                        ? element.speakerName
                        : ""}
                    </Td>
                    <Td className="nowrap pl-0 pr-0 text-center">
                      <ButtonIcon
                        small
                        className="orange"
                        onClick={() => {
                          let tmpData = this.props.dataRecallFacts;

                          tmpData[idx].isHighlighter =
                            !tmpData[idx].isHighlighter;
                          this.setDataGeneral(tmpData);
                        }}
                      >
                        <FontAwesomeIcon icon={faHighlighter} />
                      </ButtonIcon>
                      <ButtonIcon
                        small
                        className="blue"
                        onClick={() => {
                          let index = this.props.dataRecallFacts.findIndex(
                            (fact) => fact.token === element.token
                          );

                          this.setState({
                            isModalRecallFacts: true,
                            idxModalRecallFacts: index,
                          });
                        }}
                      >
                        <FontAwesomeIcon icon={faPen} />
                      </ButtonIcon>
                      <ButtonIcon
                        small
                        className="red"
                        onClick={() => {
                          window.confirmCustom(
                            "Êtes-vous sûr de vouloir supprimer ce fait ?",
                            (result) => {
                              if (result) {
                                let tmpData = this.props.dataRecallFacts;
                                let indexToDelete =
                                  this.props.dataRecallFacts.findIndex(
                                    (fact) => fact.token === element.token
                                  );

                                tmpData.splice(indexToDelete, 1);
                                this.setDataGeneral(tmpData);
                              }
                            }
                          );
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </ButtonIcon>
                      {this.context.isReport || this.context.isExpertise ? (
                        <ButtonIcon
                          small
                          className="red"
                          onClick={() => {
                            let tmpData = this.props.data;

                            tmpData[element.token] = !tmpData[element.token]
                              ? true
                              : false;

                            this.setData(tmpData);
                          }}
                        >
                          <FontAwesomeIcon icon={faEyeSlash} />
                        </ButtonIcon>
                      ) : null}
                    </Td>
                  </Tr>
                );
              } else {
                return null;
              }
            })}
          </Tbody>
        </Table>
        <div
          className="d-flex mt-10"
          style={{
            justifyContent: "end",
          }}
        >
          <div
            className="d-flex"
            style={{
              gap: 10,
            }}
          >
            <ButtonIcon
              small
              className="bg-blue"
              isBackground
              onClick={() => {
                const workBook = XLSX.utils.book_new();
                const data = filter.map((element) => ({
                  Date: element.date,
                  km_hr: element.mileage,
                  Fait: element.description,
                  Intervenant: parties[element.speaker]
                    ? `${
                        parties[element.speaker].society
                          ? `${parties[element.speaker].society} - `
                          : ""
                      }${parties[element.speaker].kind} ${
                        parties[element.speaker].name
                      }`
                    : element.speaker === "Autre"
                    ? element.speakerName
                    : "",
                }));
                getRecallFactXLSX(data, workBook);
              }}
            >
              <FontAwesomeIcon icon={faFileExcel} />
            </ButtonIcon>
            <ButtonIcon
              small
              className=" bg-blue"
              isBackground
              onClick={() => {
                getRecallFactsPDF(filter);
              }}
            >
              <FontAwesomeIcon icon={faFilePdf} />
            </ButtonIcon>
            <ButtonIcon
              small
              className="bg-blue"
              isBackground
              onClick={() =>
                this.setState({
                  isModalRecallFacts: true,
                })
              }
            >
              <FontAwesomeIcon icon={faPlus} />
            </ButtonIcon>
            <ButtonIcon
              small
              className=" bg-blue"
              isBackground
              onClick={() =>
                this.setState({
                  isModalRecallFactsSearch: true,
                })
              }
            >
              <FontAwesomeIcon icon={faSearch} />
            </ButtonIcon>
          </div>
        </div>
        <ModalRecallFactsSearch
          show={this.state.isModalRecallFactsSearch}
          onClose={() =>
            this.setState({
              isModalRecallFactsSearch: false,
            })
          }
          addRecallFacts={async (data, callback) => {
            let tmpData = this.props.dataRecallFacts;
            tmpData.push(...data);

            await this.sort();

            this.setDataGeneral(tmpData, callback);
          }}
        />
        <ModalRecallFacts
          reference={this.context.reference}
          show={this.state.isModalRecallFacts}
          idx={this.state.idxModalRecallFacts}
          data={
            this.state.idxModalRecallFacts !== null
              ? this.props.dataRecallFacts[this.state.idxModalRecallFacts]
              : {}
          }
          onClose={() =>
            this.setState({
              isModalRecallFacts: false,
              idxModalRecallFacts: null,
            })
          }
          addRecallFacts={(data, callback) => {
            this.setState(
              {
                isModalRecallFacts: false,
                idxModalRecallFacts: null,
              },
              async () => {
                let tmpData = this.props.dataRecallFacts;
                tmpData.push(data);

                await this.sort();

                this.setDataGeneral(this.props.dataRecallFacts, callback);
              }
            );
          }}
          updateRecallFacts={(data, callback) => {
            const idx = this.state.idxModalRecallFacts;
            this.setState(
              {
                isModalRecallFacts: false,
                idxModalRecallFacts: null,
              },
              async () => {
                let tmpData = this.props.dataRecallFacts;
                tmpData[idx] = data;

                await this.sort();

                this.setDataGeneral(this.props.dataRecallFacts, callback);
              }
            );
          }}
        />
      </>
    );
  };

  render() {
    if (this.props.isProtocol) {
      return this.getData();
    }

    return (
      <>
        <Card
          className="mt-10 mt-20-xl overflow-x-auto"
          title="Rappel des faits"
          disabled={this.context.isLock}
          action={
            <>
              {this.context.updateDataExpertise ||
              this.context.updateDataReport ? (
                <>
                  <ButtonIcon
                    className="blue"
                    small
                    info={
                      this.context.getJustifyCard("RecallFacts")
                        ? "Aligner à gauche"
                        : "Justifier le texte"
                    }
                    onClick={() =>
                      this.context.updateJustifyCard("RecallFacts")
                    }
                  >
                    <FontAwesomeIcon
                      info={"Trier"}
                      icon={
                        this.context.getJustifyCard("RecallFacts")
                          ? faAlignLeft
                          : faAlignJustify
                      }
                    />
                  </ButtonIcon>
                  <ButtonIcon
                    className="blue p-0"
                    small
                    info="Afficher les lignes masquées"
                    onClick={() => {
                      this.setData({});
                    }}
                  >
                    <FontAwesomeIcon icon={faSync} />
                  </ButtonIcon>
                </>
              ) : null}
              <ButtonIcon
                className="blue ml-10 p-0"
                small
                onClick={() => this.sort()}
              >
                <FontAwesomeIcon icon={faSort} />
              </ButtonIcon>
            </>
          }
          disableStyleAction
        >
          {this.getData()}
        </Card>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
