import React from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormInput, FormSelect, Modal, Button } from "ui-kit-ck-consultant";

export default class ModalFees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      object: "",
      quantity: "",
      type: "",
      price: "",
    };
  }

  handleInputChange = (e) => {
    const { value, name } = e.target;
    this.setState({
      [name]: value,
    });
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.show &&
      this.props.idx !== null &&
      prevProps.idx !== this.props.idx
    ) {
      let tmpState = this.state;
      Object.keys(this.state).forEach((key) => {
        if (this.props.data.hasOwnProperty(key)) {
          tmpState[key] = this.props.data[key];
        }
      });
      this.setState(tmpState);
    }
  }

  addFees = () => {
    if (
      this.state.date &&
      this.state.object &&
      this.state.quantity &&
      this.state.type &&
      this.state.price
    ) {
      this.props.addFees(
        {
          date: this.state.date,
          object: this.state.object,
          quantity: this.state.quantity,
          type: this.state.type,
          price: this.state.price,
        },
        () => {
          this.setState({
            date: "",
            object: "",
            quantity: "",
            type: "",
            price: "",
          });
        }
      );
    } else {
      window.alert("Veuillez remplir tous les champs");
    }
  };

  updateFees = () => {
    if (
      this.state.date &&
      this.state.object &&
      this.state.quantity &&
      this.state.type &&
      this.state.price
    ) {
      this.props.updateFees(
        this.props.idx,
        {
          date: this.state.date,
          object: this.state.object,
          quantity: this.state.quantity,
          type: this.state.type,
          price: this.state.price,
        },
        () => {
          this.setState({
            date: "",
            object: "",
            quantity: "",
            type: "",
            price: "",
          });
        }
      );
    } else {
      window.alert("Veuillez remplir tous les champs");
    }
  };

  render() {
    return (
      <Modal
          closeMessage="Êtes-vous sûr de vouloir fermer cette fenêtre ?"
          title="Honoraires"
        show={this.props.show}
        onClose={() =>
          this.setState(
            {
              date: "",
              object: "",
              quantity: "",
              type: "",
              price: "",
            },
            this.props.onClose
          )
        }
        isMove
      >
        <FormInput
          type="date"
          className="mb-10"
          name="date"
          value={this.state.date}
          title="Date"
          onChange={this.handleInputChange}
          required
        />
        <FormInput
          className="mb-10"
          name="object"
          value={this.state.object}
          title="Object"
          onChange={this.handleInputChange}
          required
        />
        <FormInput
          type="number"
          className="mb-10"
          name="quantity"
          value={this.state.quantity}
          title="Quantité"
          onChange={this.handleInputChange}
          required
        />
        <FormSelect
          className="mb-10"
          name="type"
          value={this.state.type}
          title="Type"
          ignore={true}
          init="Veuillez sélectionner un type"
          options={[
            { value: "-", text: "-" },
            { value: "hrs", text: "hrs" },
            { value: "rar", text: "rar" },
            { value: "km", text: "km" },
          ]}
          onChange={this.handleInputChange}
          required
        />
        <FormInput
          type="number"
          className="mb-10"
          name="price"
          value={this.state.price}
          title="Tarif unitaire"
          onChange={this.handleInputChange}
          required
        />
        <div className="d-flex">
          {this.props.idx === null ? (
            <Button
              className="w-auto ml-auto"
              text="Valider"
              onClick={this.addFees}
            >
              <FontAwesomeIcon icon={faCheck} />
            </Button>
          ) : (
            <Button
              className="w-auto ml-10"
              text="Modifier"
              onClick={this.updateFees}
            >
              <FontAwesomeIcon icon={faCheck} />
            </Button>
          )}
        </div>
      </Modal>
    );
  }
}
