import React from "react";
import {
  faCheck,
  faDatabase,
  faPlus,
  faSync,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from "react-flexbox-grid";
import {
  FormInput,
  FormSelect,
  Modal,
  Button,
  FormCheckbox,
  ButtonIcon,
  Loader,
} from "ui-kit-ck-consultant";

import { generateString } from "../../../utils/general";

import { writeLog } from "../../../requests/general";

import ModalDatabaseIdentificationParties from "./ModalDatabaseIdentificationParties";

import AuthContext from "../../../context/AuthContext";

import { addParty, updateParty } from "../../../requests/folder";

export default class ModalIdentificationPartiesUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      kind: "",
      name: "",
      quality: "",
      otherQuality: "",
      party: "",
      idParty: "",
      expertType: "",
      codeVE: "",
      address: "",
      address2: "",
      zipCode: "",
      city: "",
      email: "",
      phone: "",
      cellphone: "",
      society: "",
      sinister: "",
      reference: "",
      company: "",
      contract: "",
      here: false,
      isModalDatabaseIdentificationParties: false,
    };
  }

  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { value, name } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name === "quality") {
          if (value === "Lésé") {
            this.setState({
              party: "Lésé",
              idParty: "",
              sinister: "",
              reference: "",
              company: "",
              contract: "",
            });
          } else if (value === "Adverse") {
            this.setState({ party: "Adverse", idParty: 1 });
          } else if (value === "Expert") {
            this.setState({ idParty: "" });
          } else if (value === "Assuré") {
            this.setState({
              party: "Assuré",
            });
          } else if (value === "Dépositaire") {
            this.setState({
              party: "Dépositaire",
            });
          } else if (value === "Réparateur") {
            this.setState({
              party: "Réparateur",
            });
          } else {
            this.setState({
              idParty: "",
              sinister: "",
              reference: "",
              company: "",
              contract: "",
            });
          }
        } else if (name === "party") {
          if (value === "Adverse") {
            this.setState({
              idParty: 1,
              sinister: "",
              reference: "",
              company: "",
              contract: "",
            });
          } else {
            this.setState({
              idParty: "",
              sinister: "",
              reference: "",
              company: "",
              contract: "",
            });
          }
        }
      }
    );
  };

  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    this.setState({
      [name]: checked,
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.show !== prevProps.show && this.props.idx !== null) {
      let tmpState = this.state;
      Object.keys(this.state).forEach((key) => {
        if (this.context.parties[this.props.idx].hasOwnProperty(key)) {
          tmpState[key] = this.context.parties[this.props.idx][key];
        }
      });
      this.setState(tmpState);
    }
  }

  getData = () => {
    return {
      kind: this.state.kind,
      name: this.state.name,
      quality: this.state.quality,
      party: this.state.party,
      idParty: this.state.idParty,
      otherQuality: this.state.otherQuality,
      expertType: this.state.expertType,
      codeVE: this.state.codeVE,
      address: this.state.address,
      address2: this.state.address2,
      zipCode: this.state.zipCode,
      city: this.state.city,
      email: this.state.email,
      phone: this.state.phone,
      cellphone: this.state.cellphone,
      society: this.state.society,
      sinister: this.state.sinister,
      reference: this.state.reference,
      company: this.state.company,
      contract: this.state.contract,
      here: this.state.here,
    };
  };

  addUser = () => {
    if (
      this.state.kind &&
      this.state.name &&
      this.state.quality &&
      this.state.party
    ) {
      let tmpData = this.props.data;

      const key = generateString();

      tmpData[key] = this.getData();

      this.props.setData(tmpData);

      let tmpDataExpertise = this.context.dataExpertise;

      for (let index = 0; index < tmpDataExpertise.length; index++) {
        if (tmpDataExpertise[index].attendees) {
          tmpDataExpertise[index].attendees[key] = 3;
        } else {
          tmpDataExpertise[index].attendees = { [key]: 3 };
        }
      }

      this.context.updateDataFolder("dataExpertise", tmpDataExpertise);

      this.resetState();
    } else {
      window.alert("Veuillez remplir tous les champs");
    }
  };

  updateUser = () => {
    if (
      this.state.kind &&
      this.state.name &&
      this.state.quality &&
      this.state.party
    ) {
      let tmpData = this.props.data;

      tmpData[this.props.idx] = this.getData();

      this.props.setData(tmpData);
      this.resetState();
    } else {
      window.alert("Veuillez remplir tous les champs");
    }
  };

  deleteUser = () => {
    window.confirmCustom(
      "Êtes-vous sûr de vouloir supprimer cette partie ?",
      (result) => {
        if (result) {
          writeLog(this.context.folderId, "delete-party", {
            userId: this.context.id,
            party: this.props.data[this.props.idx],
          });
          let tmpData = this.props.data;
          delete tmpData[this.props.idx];
          this.props.setData(tmpData);
          this.resetState();
        }
      }
    );
  };

  resetState = () => {
    this.setState(
      {
        kind: "",
        name: "",
        quality: "",
        party: "",
        idParty: "",
        otherQuality: "",
        expertType: "",
        codeVE: "",
        address: "",
        address2: "",
        zipCode: "",
        city: "",
        email: "",
        phone: "",
        cellphone: "",
        society: "",
        sinister: "",
        reference: "",
        company: "",
        contract: "",
        here: false,
      },
      this.props.onClose
    );
  };

  getCode = (zipCode, name, city) => {
    return `${zipCode.substring(0, 2)}${name
      .replace(/ /g, "")
      .substring(0, 3)
      .toUpperCase()}${city.replace(/ /g, "").substring(0, 3).toUpperCase()}`;
  };

  render() {
    const code = this.getCode(
      this.state.zipCode,
      this.state.name,
      this.state.city
    );

    let isExist = false;
    if (this.state.zipCode && this.state.name && this.state.city) {
      isExist = !!this.context.partiesServer.filter(
        (party) => party.code === code
      ).length;
    }

    return (
      <>
        <Modal
          closeMessage="Êtes-vous sûr de vouloir fermer cette fenêtre ?"
          title="Intervenant"
          show={this.props.show}
          onClose={this.resetState}
          isMove
        >
          <Row>
            <Col xs={12} md={8} lg={7} className="mb-20">
              <Button
                text="Base intervenant"
                onClick={() =>
                  this.setState({ isModalDatabaseIdentificationParties: true })
                }
              >
                <FontAwesomeIcon icon={faDatabase} />
              </Button>
            </Col>
            <Col xs={12} md={4} lg={5} className="mb-20">
              {!isExist ? (
                <Button
                  text="Ajouter"
                  onClick={() => {
                    if (
                      this.state.kind &&
                      this.state.name &&
                      this.state.quality &&
                      this.state.party
                    ) {
                      this.setState({ isLoad: true }, () => {
                        addParty({ code, data: this.getData() }, (result) => {
                          if (result.success) {
                            let tmpPartiesServer = this.context.partiesServer;

                            tmpPartiesServer.push({
                              ...this.getData(),
                              code,
                              id: result.id,
                            });

                            this.setState({ isLoad: false }, () => {
                              this.context.updatePartiesServer(
                                tmpPartiesServer
                              );
                            });
                          } else {
                            this.setState({ isLoad: false });
                          }
                        });
                      });
                    } else {
                      window.alert("Veuillez remplir tous les champs");
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              ) : (
                <Button
                  text="Modifier"
                  onClick={() =>
                    this.setState({ isLoad: true }, () => {
                      updateParty({ code, data: this.getData() }, (result) => {
                        if (result.success) {
                          let tmpPartiesServer = this.context.partiesServer;

                          let currentIdx = null;

                          tmpPartiesServer.forEach((party, idx) => {
                            if (party.code === code) {
                              currentIdx = idx;
                            }
                          });

                          if (currentIdx !== null) {
                            tmpPartiesServer[currentIdx] = {
                              id: tmpPartiesServer[currentIdx],
                              code,
                              ...this.getData(),
                            };

                            this.setState({ isLoad: false }, () => {
                              this.context.updatePartiesServer(
                                tmpPartiesServer
                              );
                            });
                          } else {
                            this.setState({ isLoad: false });
                          }
                        }
                      });
                    })
                  }
                >
                  <FontAwesomeIcon icon={faSync} />
                </Button>
              )}
            </Col>
            <Col xs={12}>
              <p className="mt-0">CODE : {code}</p>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <FormSelect
                title="Civilité"
                className="mb-10"
                name="kind"
                value={this.state.kind}
                init="Veuillez sélectionner une civilité"
                ignore={true}
                options={[
                  { value: "M.", text: "M." },
                  { value: "Mme.", text: "Mme." },
                  { value: "Docteur", text: "Docteur" },
                  { value: "Maître", text: "Maître" },
                  { value: "Professeur", text: "Professeur" },
                  { value: "STE", text: "STE" },
                  { value: " ", text: " " },
                ]}
                onChange={this.handleInputChange}
                required
              />
            </Col>
            <Col xs={12} md={6} lg={8}>
              <FormInput
                title="Nom"
                className="mb-10"
                name="name"
                value={this.state.name}
                onChange={this.handleInputChange}
                required
              />
            </Col>
            <Col xs={12} md={6}>
              <FormSelect
                title="Qualité"
                className="mb-10"
                name="quality"
                value={this.state.quality}
                init="Veuillez sélectionner une qualité"
                ignore={true}
                disabled={
                  this.state.quality === "Lésé" &&
                  Object.keys(this.props.data).filter(
                    (key) => this.props.data[key].quality === "Lésé"
                  ).length === 1
                }
                options={[
                  { value: "Avocat", text: "Avocat" },
                  { value: "Adverse", text: "Adverse" },
                  { value: "Expert", text: "Expert" },
                  { value: "Secrétaire", text: "Secrétaire" },
                  { value: "Lésé", text: "Lésé" },
                  { value: "Tiers", text: "Tiers" },
                  { value: "Assuré", text: "Assuré" },
                  { value: "Réparateur", text: "Réparateur" },
                  { value: "Vendeur", text: "Vendeur" },
                  { value: "Constructeur", text: "Constructeur" },
                  { value: "Adverse", text: "Adverse" },
                  { value: "Mandant", text: "Mandant" },
                  { value: "Assureur", text: "Assureur" },
                  { value: "Gestionnaire", text: "Gestionnaire" },
                  { value: "Dépositaire", text: "Dépositaire" },
                  {
                    value: "Centre de contrôle technique",
                    text: "Centre de contrôle technique",
                  },
                  { value: "Propriétaire", text: "Propriétaire" },
                  { value: "Réceptionnaire", text: "Réceptionnaire" },
                  { value: "Chef d’atelier", text: "Chef d’atelier" },
                  { value: "Conseiller client", text: "Conseiller client" },
                  { value: "Technicien", text: "Technicien" },
                  { value: "Carrossier", text: "Carrossier" },
                  { value: "Gérant", text: "Gérant" },
                  { value: "Dépanneur", text: "Dépanneur" },
                  { value: "Autre", text: "Autre" },
                ]}
                onChange={this.handleInputChange}
                required
              />
              {this.state.quality === "Autre" ? (
                <>
                  <FormInput
                    name="otherQuality"
                    value={this.state.otherQuality}
                    className="mb-10"
                    title="Autre qualité"
                    onChange={this.handleInputChange}
                  />
                </>
              ) : null}
            </Col>

            <Col xs={12} md={6}>
              <div className="d-flex flex-column h-100">
                {![
                  "Lésé",
                  "Assuré",
                  "Tiers",
                  "Adverse",
                  "Dépositaire",
                ].includes(this.state.quality) ||
                this.state.quality !== this.state.party ? (
                  <FormSelect
                    title="Partie représentée"
                    className="mb-10"
                    name="party"
                    value={this.state.party}
                    init="Veuillez sélectionner une partie"
                    ignore={true}
                    options={[
                      { value: "Lésé", text: "Lésé" },
                      { value: "Tiers", text: "Tiers" },
                      { value: "Assuré", text: "Assuré" },
                      { value: "Assureur", text: "Assureur" },
                      { value: "Adverse", text: "Adverse" },
                      { value: "Réparateur", text: "Réparateur" },
                      { value: "Dépositaire", text: "Dépositaire" },
                      {
                        value: "Centre de contrôle technique",
                        text: "Centre de contrôle technique",
                      },
                      { value: "Aucune", text: "Aucune" },
                    ]}
                    onChange={this.handleInputChange}
                    required
                  />
                ) : null}
                {["Adverse", "Tiers"].includes(this.state.party) ? (
                  <div className="d-flex mt-auto mb-10">
                    {(() => {
                      let items = [];
                      for (let index = 1; index < 6; index++) {
                        items.push(
                          <ButtonIcon
                            className={`mr-10 ${
                              index === this.state.idParty
                                ? ""
                                : "bg-blue-light"
                            }`}
                            isBackground
                            onClick={() => this.setState({ idParty: index })}
                          >
                            {index}
                          </ButtonIcon>
                        );
                      }

                      return items;
                    })()}
                  </div>
                ) : null}
              </div>
            </Col>
            {["Expert"].includes(this.state.quality) ? (
              <Col xs={12} md={6}>
                <FormSelect
                  title="Type d'expert"
                  className="mb-10"
                  name="expertType"
                  value={this.state.expertType}
                  init="Veuillez sélectionner un type d'expert"
                  ignore={true}
                  options={[
                    {
                      value: "Numéro d’agrément VE",
                      text: "Numéro d’agrément VE",
                    },
                    {
                      value: "Expert en formation",
                      text: "Expert en formation",
                    },
                    {
                      value: "Expert Judiciaire",
                      text: "Expert Judiciaire",
                    },
                    { value: "Conseil technique", text: "Conseil technique" },
                    { value: "Aucun", text: "Aucun" },
                  ]}
                  onChange={this.handleInputChange}
                  required
                />
              </Col>
            ) : null}
            {["Numéro d’agrément VE"].includes(this.state.expertType) ? (
              <Col xs={12} md={6}>
                <FormInput
                  title="Numéro d’agrément VE"
                  className="mb-10"
                  name="codeVE"
                  value={this.state.codeVE}
                  onChange={this.handleInputChange}
                />
              </Col>
            ) : null}

            <Col xs={12}>
              <FormInput
                title="Adresse"
                className="mb-10"
                name="address"
                value={this.state.address}
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12}>
              <FormInput
                title="Adresse 2"
                className="mb-10"
                name="address2"
                value={this.state.address2}
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput
                title="Code postal"
                className="mb-10"
                name="zipCode"
                value={this.state.zipCode}
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput
                title="Ville"
                className="mb-10"
                name="city"
                value={this.state.city}
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput
                title="Email"
                type="email"
                className="mb-10"
                name="email"
                value={this.state.email}
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput
                title="Téléphone fixe"
                className="mb-10"
                name="phone"
                value={this.state.phone}
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput
                title="Téléphone portable"
                className="mb-10"
                name="cellphone"
                value={this.state.cellphone}
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput
                title="Société"
                className="mb-10"
                name="society"
                value={this.state.society}
                onChange={this.handleInputChange}
              />
            </Col>
            {["Expert", "Adverse", "Assureur"].includes(this.state.quality) ? (
              <>
                <Col xs={12} md={6}>
                  <FormInput
                    title="Numéro sinistre"
                    className="mb-10"
                    name="sinister"
                    value={this.state.sinister}
                    onChange={this.handleInputChange}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <FormInput
                    title="Référence dossier"
                    className="mb-10"
                    name="reference"
                    value={this.state.reference}
                    onChange={this.handleInputChange}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <FormInput
                    title="Nom compagnie"
                    className="mb-10"
                    name="company"
                    value={this.state.company}
                    onChange={this.handleInputChange}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <FormSelect
                    title="Contrat"
                    className="mb-10"
                    name="contract"
                    value={this.state.contract}
                    init="Veuillez sélectionner un contrat"
                    ignore={true}
                    options={[
                      {
                        value: "",
                        text: "Aucun contrat",
                      },
                      {
                        value: "Protection Juridique",
                        text: "Protection Juridique",
                      },
                      {
                        value: "Responsabilité civile",
                        text: "Responsabilité civile",
                      },
                      {
                        value: "Responsabilité civile professionnelle",
                        text: "Responsabilité civile professionnelle",
                      },
                      { value: "Malfaçons", text: "Malfaçons" },
                      { value: "Garantie dommages", text: "Garantie dommages" },
                      { value: "Privé", text: "Privé" },
                    ]}
                    onChange={this.handleInputChange}
                  />
                </Col>
              </>
            ) : null}
            <Col xs={12} md={6} className="d-flex">
              <FormCheckbox
                text="Présence"
                className="mb-20 mt-auto"
                name="here"
                checked={this.state.here}
                onChange={this.handleCheckboxChange}
              />
            </Col>
          </Row>
          <div className="d-flex">
            {this.props.idx === null ? (
              <Button
                className="w-auto ml-auto"
                text="Valider"
                onClick={this.addUser}
              >
                <FontAwesomeIcon icon={faCheck} />
              </Button>
            ) : (
              <>
                {!this.props.isHideDelete &&
                this.props.data[this.props.idx].quality !== "Lésé" ? (
                  <Button
                    className="w-auto ml-auto"
                    text="Supprimer"
                    onClick={this.deleteUser}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                ) : null}

                <Button
                  className={`w-auto ${
                    this.props.isHideDelete ||
                    this.props.data[this.props.idx].quality === "Lésé"
                      ? "ml-auto"
                      : "ml-10"
                  }`}
                  text="Modifier"
                  onClick={this.updateUser}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </Button>
              </>
            )}
          </div>
        </Modal>
        <ModalDatabaseIdentificationParties
          show={this.state.isModalDatabaseIdentificationParties}
          onClose={() =>
            this.setState({ isModalDatabaseIdentificationParties: false })
          }
          onValid={(party) => {
            this.setState({
              ...party,
            });
          }}
        />
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
